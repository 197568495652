import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import '../styles/layout.scss'
import styles from './layout.module.css'
import '../styles/typography.scss'

const Layout = ({children}) => (
    <SimpleReactLightbox className={styles.content}>
      {children}
    </SimpleReactLightbox>
)

export default Layout
